<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<FullCalendar :options="calendarOptions" v-if="calendarVisible" />
				</div>
			</div>
		</a-spin>
		<div>
			<a-modal :visible="modalVisible" title="Registro de pago recurrente" :closable="true" width="70%" @cancel="onCloseModal">
				<template slot="footer">
					<a-button key="back" @click="onCloseModal"> Cancelar </a-button>
					<a-button key="submit" class="btn btn-success" @click="onSave" v-if="!isEditable"> Guardar </a-button>
				</template>
				<div class="row">
					<div :class="isEditable ? 'col-md-8' : 'col-md-12'">
						<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
							<div class="row">
								<div class="col-md-4">
									<b>Ubicación</b>
									<a-select style="width: 100%" v-model="payload.branch_office_id">
										<a-select-option value="" selected>Selecciona</a-select-option>
										<a-select-option value="1">Matriz</a-select-option>
										<a-select-option value="2">Centro</a-select-option>
										<a-select-option value="3"> Chalco </a-select-option>
									</a-select>
								</div>
								<div class="col-md-4">
									<b>Proveedor</b>
									<a-select show-search allow-clear option-filter-prop="children" :filter-option="filterOption" v-model="payload.supplier_id" style="width: 100%" @change="onSelectSupplier">
										<a-select-option v-for="(supplier, index) in suppliersList" :key="index" :value="supplier.id">{{ supplier.tradename.toUpperCase() }}</a-select-option>
									</a-select>
								</div>
								<div class="col-md-4">
									<b>Método de Pago</b>
									<a-select v-model="payload.payment_method_index" style="width: 100%">
										<a-select-option v-for="(element, index) in actualSupplier.payment_methods" :key="index" :value="index">{{
											`${element.payment_method == '1' ? 'Efectivo' : 'Digital'}${Number(element.bank) == -1 ? '' : ` --> ${element.bankName}`}`
										}}</a-select-option>
									</a-select>
								</div>
							</div>
							<div class="row pt10">
								<div class="col-md-4">
									<b>Cuenta de retiro</b>
									<a-select style="width: 100%" v-model="payload.destination">
										<a-select-option value="" selected>Selecciona</a-select-option>
										<a-select-option value="panel">Morado</a-select-option>
										<a-select-option value="pacific">Azul</a-select-option>
										<a-select-option value="rls">Gris</a-select-option>
									</a-select>
								</div>
								<div class="col-md-4">
									<b>Fecha de primer ODC</b>
									<a-date-picker v-model="payload.purchase_order_date" :value-format="'YYYY-MM-DD'" :format="'DD-MM-YYYY'" placeholder="Selecciona la fecha" style="width: 100%" />
								</div>
								<div class="col-md-4">
									<b>Fecha de primer pago</b>
									<a-date-picker v-model="payload.payment_date" :value-format="'YYYY-MM-DD'" :format="'DD-MM-YYYY'" placeholder="Selecciona la fecha" style="width: 100%" />
								</div>
							</div>
							<div class="row pt10">
								<div class="col-md-4">
									<b>Periodicidad</b>
									<a-select style="width: 100%" v-model="payload.period">
										<a-select-option value="" selected>Selecciona</a-select-option>
										<a-select-option value="s">Semanal</a-select-option>
										<a-select-option value="q">Quincenal</a-select-option>
										<a-select-option value="m">Mensual</a-select-option>
									</a-select>
								</div>
								<div class="col-md-4">
									<b>Número de Pagos</b>
									<a-input v-model="payload.payments" v-mask="'###'" />
								</div>
								<div class="col-md-4">
									<b>Monto de Pago</b>
									<a-input autocomplete="off" prefix="$" v-money="money" v-model="payload.amount" />
								</div>
							</div>
							<div class="row pt10">
								<div class="col-md-12">
									<b>Descripción</b>
									<a-input v-model="payload.description" class="text-uppercase" />
								</div>
							</div>
						</a-spin>
					</div>
					<div class="col-md-4" v-if="isEditable">
						<b>Pagos relacionados al registro</b>
						<div v-for="(element, index) in relatedPayments" :key="index">
							- {{ element.description }} || {{ moment(element.payment_date).format('DD-MM-YYYY') }} <small style="color: red; cursor: pointer" @click="onDelete(index)">Eliminar</small>
						</div>
					</div>
				</div>
			</a-modal>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import utilities from '@/services/utilities'
import numeral from 'numeral'

export default {
	name: 'recurringPaymentsComponent',
	components: {
		FullCalendar,
	},
	directives: {
		mask,
		money: VMoney,
	},
	computed: {
		...mapGetters('recurringPayments', ['spinnerLoaderLabel', 'spinnerLoader', 'recurringPaymentsList']),
		...mapGetters('suppliers', ['suppliersList', 'actualSupplier']),
	},
	data() {
		return {
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			isEditable: false,
			payload: {
				branch_office_id: '',
				supplier_id: '',
				payment_method_index: 0,
				destination: '',
				purchase_order_date: '',
				payment_date: '',
				period: '',
				payments: '',
				description: '',
				amount: '',
			},
			actualDate: moment().format('YYYY-MM'),
			calendarVisible: false,
			calendarOptions: {
				plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
				initialView: 'dayGridMonth',
				locale: esLocale,
				businessHours: {
					daysOfWeek: [1, 2, 3, 4, 5, 6],
				},
				headerToolbar: {
					start: '',
					center: 'title',
					end: 'today prev,next',
				},
				events: [],
				dateClick: (payload) => {
					// console.log('dateClick', payload.dateStr);
					this.onAddRecord()
					this.payload.payment_date = payload.dateStr
				},
				eventClick: (payload) => {
					// console.log('eventClick', payload.event.id);
					this.loadEvent(payload.event.id)
				},
				datesSet: (payload) => {
					let changeDate = moment(payload.view.currentStart).format('YYYY-MM')
					// console.log({ changeDate, actualDate: this.actualDate });

					if (this.actualDate != changeDate) {
						this.actualDate = _.clone(changeDate)
						// console.log('carga datos');
						this.initModule()
					}
				},
			},
			modalVisible: false,
			relatedPayments: [],
		}
	},
	mounted() {
		this.initModule()
		if (this.suppliersList.length == 0) {
			this.$store.dispatch('suppliers/GET_NO_REDIRECT')
		}
	},
	methods: {
		moment,
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		loadEvent(id) {
			let event = this.recurringPaymentsList.find((e) => e.id == id)
			this.isEditable = true
			this.payload = {
				branch_office_id: utilities.objectValidate(event, 'branch_office_id', 1).toString(),
				supplier_id: utilities.objectValidate(event, 'supplier_id', 1),
				destination: utilities.objectValidate(event, 'payment_method', 1),
				purchase_order_date: utilities.objectValidate(event, 'purchase_order_date', 1),
				payment_date: utilities.objectValidate(event, 'payment_date', 1),
				description: utilities.objectValidate(event, 'description', 1),
				period: utilities.objectValidate(event, 'period', 1),
				payments: utilities.objectValidate(event, 'payments', 1),
			}
			this.onSelectSupplier(true, utilities.objectValidate(event, 'payment_method_index', 0))
			this.$store.dispatch('recurringPayments/GET_BY_GROUP', event.group).then((response) => {
				this.relatedPayments = _.cloneDeep(response.data)
				this.payload.amount = numeral(event.amount).format('0,0.00')
			})
		},
		cleanLocal() {
			this.calendarOptions.events = []
			this.relatedPayments = []
		},
		async initModule() {
			this.cleanLocal()

			this.$store
				.dispatch('recurringPayments/GET', {
					filterDate: this.actualDate,
				})
				.then((response) => {
					// console.log('recurringPayments/GET', response);
					response.forEach((e) => {
						this.calendarOptions.events.push({
							id: Number(e.id),
							title: e.description.toUpperCase(),
							start: moment(e.payment_date).format('YYYY-MM-DD'),
							group: e.group,
						})
					})
					this.calendarVisible = true
				})
		},
		onCloseModal() {
			this.isEditable = false
			this.modalVisible = false
			this.payload = {
				branch_office_id: '',
				supplier_id: '',
				payment_method_index: 0,
				destination: '',
				purchase_order_date: '',
				payment_date: '',
				period: '',
				payments: '',
				description: '',
				amount: '',
			}
			this.relatedPayments = []
		},
		onAddRecord() {
			this.isEditable = false
			this.modalVisible = true
			this.$store.commit('SET_STATE', {
				actualSupplier: {},
			})
		},
		onSelectSupplier(fromEdit = false, payment_method_index) {
			this.payload.payment_method_index = ''
			this.$store.dispatch('suppliers/GET_ONE_NO_REDIRECT', this.payload.supplier_id).then((resolve) => {
				if (fromEdit) {
					this.payload.payment_method_index = payment_method_index
					this.modalVisible = true
				}
			})
		},
		onSave() {
			let errors = []

			if (this.payload.branch_office_id == '') {
				errors.push('- Selecciona ubicación')
			}
			if (this.payload.supplier_id == '') {
				errors.push('- Selecciona proveedor')
			}
			if (this.payload.payment_method_index === '') {
				errors.push('- Selecciona método de pago')
			}
			if (this.payload.destination == '') {
				errors.push('- Selecciona cuenta de retiro')
			}
			if (this.payload.purchase_order_date == '') {
				errors.push('- Ingresa fecha de ODC')
			}
			if (this.payload.payment_date == '') {
				errors.push('- Ingresa fecha de pago')
			}
			if (this.payload.period == '') {
				errors.push('- Selecciona periodicidad')
			}
			if (this.payload.payments == '') {
				errors.push('- Ingresa número de pagos')
			}
			if (this.payload.description == '') {
				errors.push('- Ingresa descripción del pago')
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}

			this.$store.dispatch('recurringPayments/CREATE', this.payload).then((response) => {
				Swal.fire({
					title: 'Atención',
					html: response.message,
					icon: 'success',
					confirmButtonText: 'Ok',
				})
					.then(() => {
						this.onCloseModal()
					})
					.then(() => {
						this.initModule()
					})
			})
		},
		onDelete(index) {
			let element = this.relatedPayments[index]

			Swal.fire({
				title: 'Atención',
				text: `¿Deseas eliminar el pago del día ${moment(element.payment_date).format('DD-MM-YYYY')}?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('recurringPayments/DELETE', element.id).then((response) => {
						this.onCloseModal()
						this.initModule()
					})
				}
			})
		},
	},
}
</script>

<style >
.fc-toolbar-title {
	text-transform: uppercase;
}
</style>